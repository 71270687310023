/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { HttpImplService } from 'src/app/http/http-impl.service';
import { environment } from 'src/environments/environment';
import {
  AvaliacaoToSave,
  ImagensUsuarioOrgaoDTO,
  Perfil,
  PerfilResponse,
  Usuario,
} from './usuario.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  http = inject(HttpImplService);

  usuarioUrl = `${environment.apiUrl}v2/usuario`;
  vinculacaoUrl = `${environment.apiUrl}vinculacao`;
  vinculacaoUrlV2 = `${environment.apiUrl}vinculacao/v2`;

  findUsuarioById = (id: number) => {
    return this.http.get<Usuario>(`${this.usuarioUrl}/${id}`);
  };

  findPerfisByOrgaoId = (idOrgao: number) => {
    return this.http.get<Perfil[]>(`${this.usuarioUrl}/perfil/${idOrgao}`);
  };

  updatePerfis = (idUsuarioOrgaoEmEdicao: number, perfis: number[]) => {
    return this.http.put<PerfilResponse[]>(
      `${this.vinculacaoUrlV2}/${idUsuarioOrgaoEmEdicao}/perfis`,
      perfis,
    );
  };

  updateUsuarioOrgaoIsActiveState = (
    idUsuarioOrgaoEmEdicao: number,
    ativo: boolean,
    justificativa: string,
  ) => {
    return this.http.put(
      `${this.vinculacaoUrl}/ativar/${idUsuarioOrgaoEmEdicao}?ativo=${ativo}&justificativa=${justificativa}`,
      null,
    );
  };

  updateAvaliacao = (
    idUsuarioOrgaoEmEdicao: number,
    avaliacao: AvaliacaoToSave,
  ) => {
    return this.http.post(
      `${this.vinculacaoUrl}/validar/${idUsuarioOrgaoEmEdicao}`,
      avaliacao,
    );
  };

  findFotosByUsuarioOrgaoId = (id: number) => {
    return this.http.get<ImagensUsuarioOrgaoDTO>(
      `${this.vinculacaoUrl}/imagens/${id}`,
    );
  };

  // De uso da tela de login
  criarUsuario(claimsGovBr): Observable<any> {
    return this.http.post(`${this.usuarioUrl}`, claimsGovBr);
  }

  atualizarUsuario(claimsGovBr): Observable<any> {
    return this.http.put(`${this.usuarioUrl}`, claimsGovBr);
  }
}
